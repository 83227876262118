import React, { useState } from 'react';
import { SheetMusicBrowseCommon } from './../browse/SheetMusicBrowseCommon';
import { Header, Divider, Icon } from 'semantic-ui-react'

export function PersonSheetMusic(props) {
	const [childRendering, setChildRendering] = useState(true);
	return (
		<>
			{childRendering &&
				<>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='song' />
							Sheet Music
						</Header>
					</Divider>
					<SheetMusicBrowseCommon slim={true} person={props.person}
						urlParams={false} relatedRendered={(val) => setChildRendering(val)}/>
				</>
			}
		</>
	);
}