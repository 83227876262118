import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';

import { Icon, Table, Dimmer, Loader, Segment, Button, Grid, Divider, Pagination, Input, Header } from 'semantic-ui-react'

import { PersonRecordings } from './../subcomponents/PersonRecordings';
import { PersonSongs } from './../subcomponents/PersonSongs';
import { PersonCalendar } from './../subcomponents/PersonCalendar';
import { PersonPublications } from './../subcomponents/PersonPublications';
import { PersonSheetMusic } from './../subcomponents/PersonSheetMusic';
import { ReferencesPopup } from './../subcomponents/ReferencesPopup';

export function PersonBrowseCommon(props) {
	const navigate = useNavigate();
	const {id} = useParams();
	const ref = React.useRef(null);
	const idToOpenRef = React.useRef(null);

	const [idToUse, setIdToUse] = useState(props.urlParams ? id : null);
	const [lastId, setLastId] = useState(id);
	useEffect(() => { if(lastId !== id ) { setIdToUse(id); setLastId(id); setLoading(true); } }, [idToUse, id, lastId])
	const [scrollToId, setScrollToId] = useState(true);
	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [rowsToOpen, setRowsToOpen] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "name",
		query: "",
		type: []
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'string',
			},
			stringifyOptions: {
				arrayFormat: 'string',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [sortByName, setSortByName] = useState(state.sort === 'name');

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(stateTmp[key] !== undefined && state[key] !== stateTmp[key]) {
				stateToSet[key] = stateTmp[key];
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);
	
	const search = useCallback(() => {
		let pagetmp = state.page;
		if(resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/person/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}
		if(props.urlParams && !idToUse) {
			navigate('/person' + url + urlAttachment);
		}

		if(idToUse) {
			urlAttachment += '&person=' + idToUse;
			setScrollToId(true);
			setRowsToOpen([...rowsToOpen, idToUse]);			
		} else {
			setScrollToId(false);
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.people;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if(first_tmp < 0 ) {
					first_tmp = 0;
				}
				if(first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if(last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
				setIdToUse(null);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, navigate, firstLoad, idToUse, props, rowsToOpen]);	

	const handlePaginationClick = (e, { activePage }) => { stateHelper({page: activePage}); setChangePage(true); };
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => {
		if(scrollToId && idToOpenRef && idToOpenRef.current) {
			idToOpenRef.current.scrollIntoView({behavior: 'smooth'});
		}
	});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => { if(loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if(changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.sort])
	useEffect(() => {
		if(state.sort === 'name') {
			setSortByName(true);
		} else {
			setSortByName(false);
		}
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				<Grid columns={3} stackable relaxed='very' className="width100">
					<Grid.Column>
						<Button.Group>
							<Button positive={sortByName} onClick={() => changeSortField('name')}>Sort by Name</Button>
						</Button.Group>
					</Grid.Column>
					<Grid.Column>
						<Input inverted fluid
							action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
							placeholder='Search...'
							value={queryTmp}
							onChange={(e, { value }) => setQueryTmp(value)}
							onBlur={(e) => stateHelper({query:queryTmp})}
							onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
						/>
					</Grid.Column>
				</Grid>
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell style={{width: '5%'}}>View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No people matches your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(person){
							var json_obj = {};
							if(rowsToOpen.includes(person.id)) {
								try {
									json_obj = JSON.parse(person.json[0]);
								} catch(ex) {
									console.log(ex);
								}
							}
							var row_link = '/person/' + person.id;
							return(
								<Fragment key={person.id}>
									<Table.Row key={person.id} onClick={() => 
									{
										if(rowsToOpen.includes(person.id)) {
											setRowsToOpen(rowsToOpen.filter(id => id !== person.id));
										} else {
											setRowsToOpen([...rowsToOpen, person.id]);
										}
									}} className='browse-table-row'>
										<Table.Cell>{person.name}</Table.Cell>
										<Table.Cell>
											{rowsToOpen.includes(person.id) && <Icon name='angle up'/>}
											{!rowsToOpen.includes(person.id) && <Icon name='angle down'/>}
										</Table.Cell>
									</Table.Row>
									{rowsToOpen.includes(person.id) &&
										<Table.Row key={person.id + '_open'} ref={idToOpenRef}>
											<Table.Cell className="merchandise_open" colSpan={6}>
												<Grid>
													<Grid.Column width={7} floated='right'>
														<Divider horizontal inverted>
															<Header as='h4' inverted>
																<Icon name='info circle' />
																Details
															</Header>
														</Divider>
														<Table definition inverted>
															<Table.Body>
																<Table.Row>
																	<Table.HeaderCell width={2}>Name</Table.HeaderCell>
																	<Table.Cell>{json_obj.fullName}</Table.Cell> 
																</Table.Row>
															</Table.Body>
														</Table>
													</Grid.Column>
													<Grid.Column width={7} floated='left'>
													</Grid.Column>

													<Grid.Row>
														<PersonCalendar person={person.id}/>
													</Grid.Row>
													<Grid.Row>
														<PersonSongs person={person.id}/>
													</Grid.Row>
													<Grid.Row>
														<PersonRecordings person={person.id}/>
													</Grid.Row>
													<Grid.Row>
														<PersonPublications person={person.id}/>
													</Grid.Row>
													<Grid.Row>
														<PersonSheetMusic person={person.id}/>
													</Grid.Row>

													<Grid.Column width={3} floated='right' style={{ textAlign: 'right' }}>
														{('references' in json_obj && json_obj.references.length > 0) &&
															<ReferencesPopup references={json_obj.references}/>
														}
														<a href={row_link}><Icon name='linkify'/></a>
													</Grid.Column>
												</Grid>
											</Table.Cell>
										</Table.Row>
									}
								</Fragment>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} people.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</div>
	);
}